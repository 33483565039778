
import { associates, IAssociate, IAssociateUpdateRequest, IGroup } from "@/api";
import UserIcon from "@/components/common/UserIcon.vue";
import { Actions, Mutations } from "@/store/models";
import { ASSOCIATE_ROLES, getYear } from "@/utils";
import Vue from "vue";
// import AssignGroups from "@/components/common/AsignGroups.vue";

export default Vue.extend({
  name: "UserProfile",
  components: {
    UserIcon,
    // CompanyHeader,
    // AssignGroups,
  },
  data() {
    return {
      originalUser: {} as IAssociate,
      user: {} as IAssociateUpdateRequest,
      roles: ASSOCIATE_ROLES,
      file: undefined,
      disabledPermissionsGroupsUpdates:
        process.env.VUE_APP_DISABLE_PROFILE_PERMISSIONS_GROUPS_UPDATES ===
        "true",
      editBiography: false,
    };
  },
  computed: {
    id(): number {
      return Number(this.$route.params.userId);
    },
    groups(): IGroup[] {
      return this.$store.state.company.groups;
    },
    disableEditing(): boolean {
      return this.id !== this.$store.state.associate?.id;
    },
    yearCreated(): string {
      return getYear(this.originalUser.created_datetime);
    },
  },
  created() {
    this.getUser();
  },
  methods: {
    async getUser() {
      try {
        this.originalUser = (await associates.get(this.id)).data[0];
        this.user = {
          ...this.originalUser,
          first_name: this.originalUser.first_name,
          last_name: this.originalUser.last_name,
          middle_name: this.originalUser.middle_name || undefined,
          user_name: this.originalUser.user_name,
          biography: this.originalUser.biography || undefined,
          roles: this.originalUser.roles || [],
          associate_groups: this.originalUser.associate_associates_groups.map(
            (g) => g.associates_group.id
          ),
        };
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async update() {
      try {
        if (
          this.user.user_name &&
          this.user.user_name !== this.originalUser.user_name
        ) {
          this.$store.dispatch(
            Actions.DisplayError,
            "Updating email is not supported yet"
          );
          return;
        }
        if (this.$store.state.associate.id === this.id) {
          await associates.updateProfile(this.id, {
            first_name: this.user.first_name,
            last_name: this.user.last_name,
            // roles: this.user.roles,
            biography: this.user.biography,
          } as any);
        } else {
          await associates.update(this.id, {
            first_name: this.user.first_name,
            last_name: this.user.last_name,
            // roles: this.user.roles,
            biography: this.user.biography,
          } as any);
        }

        if (this.file) {
          await associates.uploadProfilePic(this.id, this.file!);
        }
        this.$store.commit(Mutations.SetToast, {
          text: "Profile updated successfully.",
        });
        this.getUser();

        this.$store.dispatch(Actions.GetCompanyAssociates);
        this.$store.dispatch(Actions.GetCompanyGroups);

        // if (this.$route.name === "GroupUserProfile") {
        this.$router.go(-1);
        // }
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    updateGroups(val: number[]) {
      this.user.associate_groups = val;
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  watch: {
    id() {
      if (this.id) {
        this.getUser();
      }
    },
  },
});
